<template>
  <form action="#" @submit.prevent="handleSubmit">

    <div class="common-modal-headline">
      <h4 class="common-modal-title">
        {{ isNew ? $t(`trackers.add-new-tracker`) : $t(`trackers.edit-tracker`) }}
      </h4>
      <span class="material-symbols-rounded common-modal-close" @click="$emit('onClose')">
        close
      </span>
    </div>

    <hr class="my-3" />

    <b-alert variant="danger" dismissible class="mt-3" :show="Boolean(error)">
      <div v-html="error" />
    </b-alert>

    <div class="tracker-list mb-3" :class="{ 'tracker-list-new': isNew, 'tracker-list-counted': form.length > 1 }">
      <div class="tracker-item" v-for="(tracker, i) in form" :key="tracker.id">
        <div class="tracker-item-form">
          <div class="row">

            <!-- number -->
            <div class="col-lg-2">
              <div class="form-group mb-2 mb-lg-0">
                <label :class="{ required: isNew }">{{ $t('form.tracker-number') }}</label>
                <div class="input-group input-group-merge">
                  <input v-model="tracker.number" class="form-control text-center" maxlength="4" placeholder="####"
                    :class="{
                      'is-invalid': submitted && $v.form.$each[i].number.$error,

                    }" />
                  <div v-if="submitted && $v.form.$each[i].number.$error" class="invalid-feedback">
                    <div v-if="isNew && !$v.form.$each[i].number.required">
                      {{ $t('form.tracker-number-req') }}
                    </div>
                    <div v-if="!$v.form.$each[i].number.used">{{ $t('msg.NUMBER_USED') }}</div>
                  </div>
                </div>
              </div>
            </div>

            <!-- name -->
            <div class="col-lg-5">
              <div class="form-group mb-2 mb-lg-0">
                <label :class="{ required: isNew }">{{ $t('form.tracker-name') }}</label>
                <div class="input-group input-group-merge">
                  <input v-model="tracker.name" class="form-control" placeholder="Enter name" :class="{
                    'is-invalid': isNew && submitted && $v.form.$each[i].name.$error,
                  }" />
                  <div v-if="isNew && submitted && $v.form.$each[i].name.$error" class="invalid-feedback">
                    <div v-if="!$v.form.$each[i].name.required">
                      {{ $t('form.tracker-name-req') }}
                    </div>
                  </div>
                </div>
              </div>
            </div>

            <!-- mac -->
            <div class="col-lg-5">
              <div class="form-group mb-2 mb-lg-0">
                <label :class="{ required: isNew }">{{ $t('form.tracker-mac') }}</label>
                <div class="input-group input-group-merge">
                  <input v-model="tracker.mac" class="form-control" v-mask="'NN:NN:NN:NN:NN:NN'"
                    placeholder="##:##:##:##:##:##" :class="{
                      'is-invalid': submitted && $v.form.$each[i].mac.$error,
                    }" />
                  <div v-if="submitted && $v.form.$each[i].mac.$error" class="invalid-feedback">
                    <div v-if="isNew && !$v.form.$each[i].mac.required">
                      {{ $t('form.tracker-mac-req') }}
                    </div>
                    <div v-if="!$v.form.$each[i].mac.macAddress">{{ $t('form.tracker-mac-not-valid') }}</div>
                  </div>
                </div>
              </div>
            </div>

            <!-- company_id -->
            <!-- Select company only admin and if tracker is new -->
            <div class="col-12" v-if="role === 'admin' && isNew">
              <div class="form-group mb-0">
                <label class="required">{{ $t('form.company') }}</label>
                <div class="input-group input-group-merge">
                  <AutocompleteSearchCompany v-model="tracker.company" :class-input="{
                    'is-invalid': submitted && $v.form.$each[i].company.$error,
                  }
                    " />
                  <div v-if="submitted && $v.form.$each[i].company.$error" class="invalid-feedback">
                    <div v-if="!$v.form.$each[i].company.required">
                      {{ $t('form.company-req') }}
                    </div>
                  </div>
                </div>
              </div>
            </div>

          </div>
        </div>
        <div class="tracker-item-action ml-3 mt-auto" v-if="isNew">
          <a class="btn btn-outline-primary btn-ico" title="Add tracker" href="#" @click.prevent="addTracker"
            v-if="form.length < addedTrackersLimit">
            <span class="material-symbols-rounded">
              add_circle
            </span>
          </a>
          <a class="btn btn-transparent btn-ico btn-ico-remove" title="Remove tracker" href="#"
            @click.prevent="removeTracker(tracker.id)" v-if="form.length > 1">
            <span class="material-symbols-rounded">
              <span class="material-symbols-rounded">
                do_not_disturb_on
              </span>
            </span>
          </a>
        </div>
      </div>
    </div>

    <hr class="mt-2 mb-1" />

    <div class="mt-3 text-center text-md-left">
      <button class="btn btn-primary" type="submit" :disabled="trySubmit">
        {{ isNew ? $t(`btn.add-tracker`) : $t('btn.save-changes') }}
      </button>
    </div>

    <!-- <pre>tracker: {{ tracker }}</pre> -->
    <!-- <pre>form: {{ form }}</pre> -->
    <!-- <pre>error: {{ error }}</pre> -->
    <!-- <pre>used: {{ used }}</pre> -->
  </form>
</template>

<script>
import { mapActions, mapState } from 'pinia'
import { useGlobalStore } from '@/store/global'
import AutocompleteSearchCompany from "@/components/autocomplete-search-company";

import {
  required,
  minLength,
  maxLength,
  macAddress,
  numeric,
} from "vuelidate/lib/validators";

export default {
  props: {
    tracker: {
      type: Object,
      default: () => { },
    },
  },
  components: {
    AutocompleteSearchCompany,
  },
  data() {
    return {
      error: "",

      form: [],

      submitted: false,
      trySubmit: false,

      addedTrackersLimit: 10,

      used: [],
    };
  },
  validations() {
    return {
      form: this.formRules,
      // form: {
      //   $each: {
      //     mac: {
      //       required,
      //       macAddress: macAddress(),
      //     },

      //   },
      // },
    };
  },
  created() {
    this.initForm();
  },
  computed: {
    ...mapState(useGlobalStore, ['userRoles']),
    role() {
      if (this.userRoles?.includes("a")) {
        return 'admin'
      }

      if (this.userRoles?.includes("c")) {
        return 'company'
      }

      return
    },
    isNew() {
      return !this.tracker?.id;
    },
    formRules() {
      let rules = {}

      if (this.isNew) {
        // new
        rules = {
          $each: {
            mac: {
              required,
              macAddress: macAddress(),
            },
            number: {
              required,
              numeric,
              minLength: 1,
              maxLength: 4,
              used: function (value) {
                return !this.used.includes(value)
              },
            },
            name: {
              required
            },
          },
        }
      } else {
        // edit
        rules = {
          $each: {
            mac: {
              macAddress: macAddress(),
            },
            number: {
              numeric,
              minLength: 1,
              maxLength: 4,
              used: function (value) {
                return !this.used.includes(value)
              },
            },
          },
        }
      }

      if (this.isNew && this.role === 'admin') {
        // new and admin
        rules = {
          $each: {
            mac: {
              required,
              macAddress: macAddress(),
            },
            number: {
              required,
              numeric,
              minLength: 1,
              maxLength: 4,
              used: function (value) {
                return !this.used.includes(value)
              },
            },
            name: {
              required
            },
            company: {
              required
            },
          },
        }
      }

      return rules;
    },
  },
  methods: {
    ...mapActions(useGlobalStore, ['editTracker', 'createTracker']),
    initForm() {
      let tracker = {}

      if (this.isNew) {
        // new 
        // if new tracker has company data
        tracker = {
          id: "pseudoid-" + new Date().getTime(),
          number: "",
          name: "",
          mac: "",
          company: null,
        };

        if (this.tracker?.company_id) {
          tracker.company = {
            id: this.tracker.company_id,
            name: this.tracker?.company_name || ""
          }
        }

      } else {
        // edit
        tracker = {
          id: this.tracker.id,
          number: this.tracker.number,
          name: this.tracker.name,
          mac: this.tracker.mac,
          company_id: this.tracker.company_id,
        };
      }

      this.form = [tracker]
    },
    async handleSubmit() {
      this.submitted = true;
      this.error = "";

      this.$v.$touch();

      if (this.$v.$invalid) {
        return;
      } else {
        this.trySubmit = true;

        if (this.isNew) {
          // NEW
          // console.log('Create new tracker, data: ', this.form)
          const trackers = this.form.map(tracker => {
            return {
              ...tracker,
              company_id: tracker.company.id
            }
          })
          // console.log('Create new, trackers: ', trackers)

          const req = await this.createTracker(trackers)
          // console.log('req.toJSON(): ', req.toJSON())

          if (req?.data?.success) {
            this.$emit("onSubmit");
          } else {
            this.error = req;

            if (req.response.data.errors?.length) {
              // this.error = JSON.stringify(req.response.data.errors)
              this.error = ''

              req.response.data.errors.map(e => {
                this.error += `tracker #${(e.idx + 1)} - ${this.$t(`msg.${e.error}`)}: ${trackers[e.idx].number}<br />`
              })

              // add values to used
              req.response.data.errors.map(e => {
                this.used.push(trackers[e.idx].number)
              })
            }
          }
        } else {
          // EDIT
          const req = await this.editTracker(this.form[0])
          // console.log('req.toJSON(): ', req.toJSON())

          if (req?.data?.success) {
            this.$emit("onSubmit");
          } else {
            this.error = req;

            if (req.response.data.error) {
              this.error = JSON.stringify(req.response.data.error)

              // add values to used
              if (req.response.data.error === 'NUMBER_USED') {
                this.error = `${this.$t(`msg.NUMBER_USED`)}: ${this.form[0].number}`
                this.used.push(this.form[0].number)
              }
            }
          }
        }

        this.trySubmit = false;
      }
    },
    addTracker() {
      let tracker = {
        id: "pseudoid-" + new Date().getTime(),
        number: "",
        name: "",
        mac: "",
        company: null,
      };

      if (this.tracker?.company_id) {
        tracker.company = {
          id: this.tracker.company_id,
          name: this.tracker?.company_name || ""
        }
      }

      this.form.push(tracker);
    },
    removeTracker(id) {
      // console.log('removeTracker, id: ', id)
      this.form = this.form.filter(
        (tracker) => tracker.id !== id
      );
    },

  },
  watch: {},
};
</script>

<style scoped lang="scss">
.tracker-item {
  display: flex;
  margin-top: 16px;

  &:first-child {
    margin-top: 0;
  }

  &-form {
    flex-grow: 1;
  }

  &-action {
    position: relative;
    min-width: 96px;
    height: 46px;
    top: -1px;
  }

  .btn-ico-remove {
    position: absolute;
    top: -3px;
    right: 0;
  }
}

.tracker-list .tracker-item:not(:first-child) :deep(.form-group > label) {
  display: none;
}

.tracker-list-new .tracker-item :deep(.invalid-feedback) {
  white-space: nowrap;
  position: absolute;
  left: 0;
  bottom: -17px;
}

// CSS counting .tracker-item
.tracker-list-counted {
  counter-reset: section;

  .tracker-item {
    position: relative;
  }

  .tracker-item::before {
    counter-increment: section;
    content: counter(section);
    width: 30px;
    height: 48px;
    line-height: 48px;
    position: absolute;
    left: -30px;
    bottom: 0;
    text-align: center;
    color: #ccd1d5;
    font-size: 18px;
  }
}
</style>