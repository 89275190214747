<template>
  <Layout>

    <PageTrackers />

  </Layout>
</template>

<script>
import Layout from "@/views/layouts/main";
import PageTrackers from "@/components/page-trackers";

export default {
  metaInfo() {
    return {
      title: this.$t('app.title') + " | " + this.$t('page.trackers'),
    };
  },
  components: {
    Layout,
    PageTrackers,
  },
  data() {
    return {
    };
  },
  created() {
  },
  computed: {
  },
  methods: {
  },
  watch: {
  },
};
</script>
<style scoped lang="scss"></style>
