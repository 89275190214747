var render = function render(){var _vm=this,_c=_vm._self._c;return _c('form',{attrs:{"action":"#"},on:{"submit":function($event){$event.preventDefault();return _vm.handleSubmit.apply(null, arguments)}}},[_c('div',{staticClass:"common-modal-headline"},[_c('h4',{staticClass:"common-modal-title"},[_vm._v(" "+_vm._s(_vm.isNew ? _vm.$t(`trackers.add-new-tracker`) : _vm.$t(`trackers.edit-tracker`))+" ")]),_c('span',{staticClass:"material-symbols-rounded common-modal-close",on:{"click":function($event){return _vm.$emit('onClose')}}},[_vm._v(" close ")])]),_c('hr',{staticClass:"my-3"}),_c('b-alert',{staticClass:"mt-3",attrs:{"variant":"danger","dismissible":"","show":Boolean(_vm.error)}},[_c('div',{domProps:{"innerHTML":_vm._s(_vm.error)}})]),_c('div',{staticClass:"tracker-list mb-3",class:{ 'tracker-list-new': _vm.isNew, 'tracker-list-counted': _vm.form.length > 1 }},_vm._l((_vm.form),function(tracker,i){return _c('div',{key:tracker.id,staticClass:"tracker-item"},[_c('div',{staticClass:"tracker-item-form"},[_c('div',{staticClass:"row"},[_c('div',{staticClass:"col-lg-2"},[_c('div',{staticClass:"form-group mb-2 mb-lg-0"},[_c('label',{class:{ required: _vm.isNew }},[_vm._v(_vm._s(_vm.$t('form.tracker-number')))]),_c('div',{staticClass:"input-group input-group-merge"},[_c('input',{directives:[{name:"model",rawName:"v-model",value:(tracker.number),expression:"tracker.number"}],staticClass:"form-control text-center",class:{
                    'is-invalid': _vm.submitted && _vm.$v.form.$each[i].number.$error,

                  },attrs:{"maxlength":"4","placeholder":"####"},domProps:{"value":(tracker.number)},on:{"input":function($event){if($event.target.composing)return;_vm.$set(tracker, "number", $event.target.value)}}}),(_vm.submitted && _vm.$v.form.$each[i].number.$error)?_c('div',{staticClass:"invalid-feedback"},[(_vm.isNew && !_vm.$v.form.$each[i].number.required)?_c('div',[_vm._v(" "+_vm._s(_vm.$t('form.tracker-number-req'))+" ")]):_vm._e(),(!_vm.$v.form.$each[i].number.used)?_c('div',[_vm._v(_vm._s(_vm.$t('msg.NUMBER_USED')))]):_vm._e()]):_vm._e()])])]),_c('div',{staticClass:"col-lg-5"},[_c('div',{staticClass:"form-group mb-2 mb-lg-0"},[_c('label',{class:{ required: _vm.isNew }},[_vm._v(_vm._s(_vm.$t('form.tracker-name')))]),_c('div',{staticClass:"input-group input-group-merge"},[_c('input',{directives:[{name:"model",rawName:"v-model",value:(tracker.name),expression:"tracker.name"}],staticClass:"form-control",class:{
                  'is-invalid': _vm.isNew && _vm.submitted && _vm.$v.form.$each[i].name.$error,
                },attrs:{"placeholder":"Enter name"},domProps:{"value":(tracker.name)},on:{"input":function($event){if($event.target.composing)return;_vm.$set(tracker, "name", $event.target.value)}}}),(_vm.isNew && _vm.submitted && _vm.$v.form.$each[i].name.$error)?_c('div',{staticClass:"invalid-feedback"},[(!_vm.$v.form.$each[i].name.required)?_c('div',[_vm._v(" "+_vm._s(_vm.$t('form.tracker-name-req'))+" ")]):_vm._e()]):_vm._e()])])]),_c('div',{staticClass:"col-lg-5"},[_c('div',{staticClass:"form-group mb-2 mb-lg-0"},[_c('label',{class:{ required: _vm.isNew }},[_vm._v(_vm._s(_vm.$t('form.tracker-mac')))]),_c('div',{staticClass:"input-group input-group-merge"},[_c('input',{directives:[{name:"model",rawName:"v-model",value:(tracker.mac),expression:"tracker.mac"},{name:"mask",rawName:"v-mask",value:('NN:NN:NN:NN:NN:NN'),expression:"'NN:NN:NN:NN:NN:NN'"}],staticClass:"form-control",class:{
                    'is-invalid': _vm.submitted && _vm.$v.form.$each[i].mac.$error,
                  },attrs:{"placeholder":"##:##:##:##:##:##"},domProps:{"value":(tracker.mac)},on:{"input":function($event){if($event.target.composing)return;_vm.$set(tracker, "mac", $event.target.value)}}}),(_vm.submitted && _vm.$v.form.$each[i].mac.$error)?_c('div',{staticClass:"invalid-feedback"},[(_vm.isNew && !_vm.$v.form.$each[i].mac.required)?_c('div',[_vm._v(" "+_vm._s(_vm.$t('form.tracker-mac-req'))+" ")]):_vm._e(),(!_vm.$v.form.$each[i].mac.macAddress)?_c('div',[_vm._v(_vm._s(_vm.$t('form.tracker-mac-not-valid')))]):_vm._e()]):_vm._e()])])]),(_vm.role === 'admin' && _vm.isNew)?_c('div',{staticClass:"col-12"},[_c('div',{staticClass:"form-group mb-0"},[_c('label',{staticClass:"required"},[_vm._v(_vm._s(_vm.$t('form.company')))]),_c('div',{staticClass:"input-group input-group-merge"},[_c('AutocompleteSearchCompany',{attrs:{"class-input":{
                  'is-invalid': _vm.submitted && _vm.$v.form.$each[i].company.$error,
                }},model:{value:(tracker.company),callback:function ($$v) {_vm.$set(tracker, "company", $$v)},expression:"tracker.company"}}),(_vm.submitted && _vm.$v.form.$each[i].company.$error)?_c('div',{staticClass:"invalid-feedback"},[(!_vm.$v.form.$each[i].company.required)?_c('div',[_vm._v(" "+_vm._s(_vm.$t('form.company-req'))+" ")]):_vm._e()]):_vm._e()],1)])]):_vm._e()])]),(_vm.isNew)?_c('div',{staticClass:"tracker-item-action ml-3 mt-auto"},[(_vm.form.length < _vm.addedTrackersLimit)?_c('a',{staticClass:"btn btn-outline-primary btn-ico",attrs:{"title":"Add tracker","href":"#"},on:{"click":function($event){$event.preventDefault();return _vm.addTracker.apply(null, arguments)}}},[_c('span',{staticClass:"material-symbols-rounded"},[_vm._v(" add_circle ")])]):_vm._e(),(_vm.form.length > 1)?_c('a',{staticClass:"btn btn-transparent btn-ico btn-ico-remove",attrs:{"title":"Remove tracker","href":"#"},on:{"click":function($event){$event.preventDefault();return _vm.removeTracker(tracker.id)}}},[_vm._m(0,true)]):_vm._e()]):_vm._e()])}),0),_c('hr',{staticClass:"mt-2 mb-1"}),_c('div',{staticClass:"mt-3 text-center text-md-left"},[_c('button',{staticClass:"btn btn-primary",attrs:{"type":"submit","disabled":_vm.trySubmit}},[_vm._v(" "+_vm._s(_vm.isNew ? _vm.$t(`btn.add-tracker`) : _vm.$t('btn.save-changes'))+" ")])])],1)
}
var staticRenderFns = [function (){var _vm=this,_c=_vm._self._c;return _c('span',{staticClass:"material-symbols-rounded"},[_c('span',{staticClass:"material-symbols-rounded"},[_vm._v(" do_not_disturb_on ")])])
}]

export { render, staticRenderFns }