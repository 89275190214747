<template>
  <div>

    <div class="row align-items-center text-center page-headline mb-3">
      <div class="col-md-6 text-md-left">
        <h1 class="display-3">{{ $t(`page.trackers`) }}</h1>
      </div>

      <div class="col-md-6 text-md-right">
        <button class="btn btn-primary" @click.prevent="addTracker">
          <span class="material-symbols-rounded mr-2"> add </span>{{ $t(`btn.add-tracker`) }}
        </button>
      </div>
    </div>

    <div v-if="loading">
      <div class="text-center">
        <b-spinner variant="primary" />
      </div>
    </div>
    <div v-else>
      <b-alert v-if="error" variant="danger" class="mt-3" dismissible :show="Boolean(error)">{{ error }}</b-alert>
      <template v-else>

        <div class="card">
          <div class="card-body">

            <div class="row mb-md-2 text-center table-filters">
              <div v-if="role === 'admin'" class="col-lg-6 col-md-auto text-md-left">
                <div class="table-filters-label d-block d-md-inline-flex align-items-center flex-wrap mr-2">
                  <label class="d-inline-flex align-items-center mr-2">
                    <span class="text-nowrap mr-2">{{ $t('form.filter-by-company') }}:</span>
                    <AutocompleteSearchCompany v-model="company" :key="refreshKey" />
                  </label>
                </div>
              </div>
              <div class="col-lg-6 col-md-auto ml-auto">
                <div id="tickets-table_filter" class="dataTables_filter text-md-right">
                  <label class="d-inline-flex align-items-center">
                    <span class="text-nowrap">{{ $t('form.quantity') }}:</span>
                    <b-form-select v-model="per_page" size="sm" :options="pageOptions"
                      class="form-control form-control-sm ml-2"></b-form-select>
                  </label>
                </div>
              </div>
            </div>

            <div class="table-responsive mb-0">

              <b-table :items="tableData" :fields="tableFields" responsive="sm" :per-page="per_page"
                :sort-by.sync="sortBy" :sort-desc.sync="sortDesc" :filter="filter" :filter-included-fields="filterOn"
                show-empty class="table-trackers">

                <!-- <template #cell(user)="data">
                  <router-link :to="`/admin/clients/${data.item.id}`">{{
                    userName(data.item)
                  }}</router-link>
                </template> -->

                <!-- <template #head(id)="data">
                  {{ $t('table.id') }}
                </template> -->
                <template #head(number)="data">
                  {{ $t('table.number') }}
                </template>
                <template #head(name)="data">
                  {{ $t('table.name') }}
                </template>
                <template #head(mac)="data">
                  {{ $t('table.mac') }}
                </template>
                <template #head(company_name)="data">
                  {{ $t('table.company_name') }}
                </template>
                <template #head(client_fio)="data">
                  {{ $t('table.fio') }}
                </template>

                <template #cell(action)="data">
                  <a href="#" @click.prevent="handleEditTracker(data.item)" class="action-edit"
                    :title="$t('btn.edit')"><span class="material-symbols-rounded fz-24 v-middle mx-1">
                      edit
                    </span></a>
                </template>

                <template #empty>
                  <h4 class="text-center">
                    {{ $t('table.no-data') }}
                  </h4>
                </template>
              </b-table>
            </div>
            <div class="row mb-3">
              <div class="col-12 text-center">
                <div class="dataTables_paginate paging_simple_numbers">
                  <b-pagination v-model="page" :total-rows="totalRows" :per-page="per_page"></b-pagination>
                </div>
              </div>
            </div>
          </div>
        </div>
      </template>
    </div>

    <b-modal v-model="showModal" size="lg" hide-header hide-footer centered>
      <formTracker :tracker="tracker" @onSubmit="submitForm" @onClose="showModal = false" />
    </b-modal>

  </div>
</template>

<script>
import { mapActions, mapState } from 'pinia'
import { useGlobalStore } from '@/store/global'
import formTracker from "@/components/form-tracker";
import AutocompleteSearchCompany from "@/components/autocomplete-search-company";

export default {
  components: {
    formTracker,
    AutocompleteSearchCompany,
  },
  data() {
    return {
      loading: false,
      error: "",
      wait: false,

      company: null,
      companies: [],

      tableData: [],
      totalRows: 0,
      page: 1,
      pageOptions: [25, 50, 100],
      per_page: 25,
      filter: null,
      filterOn: [],
      sortBy: "id",
      sortDesc: true,

      showModal: false,
      tracker: "",

      refreshKey: 0,
    };
  },
  created() {
    this.paramFromQuery();
    this.loadData();
  },
  computed: {
    ...mapState(useGlobalStore, {
      owner: 'user',
      userRoles: 'userRoles',
    }),
    role() {
      if (this.userRoles?.includes("a")) {
        return 'admin'
      }

      if (this.userRoles?.includes("c")) {
        return 'company'
      }

      return
    },
    tableFields() {
      let fields = [
        // {
        //   key: "id",
        //   class: "cell-id",
        // },
        {
          key: "number",
          class: "cell-id",
        },
        {
          key: "name",
        },
        {
          key: "mac",
        },
        {
          key: "company_name",
        },
        {
          key: "client_fio",
        },
        {
          key: "action",
          label: "",
          class: "cell-action",
        },
      ]

      // if company remove - field company_name
      if (this.role === 'company') {
        return fields.filter(field => field.key !== 'company_name')
      }

      return fields
    },
  },
  methods: {
    ...mapActions(useGlobalStore, ['getCompanyList', 'getTrackerList']),
    async setQuery(newQuery, toHistory = false) {
      const query = Object.assign({}, { ...this.$route.query, ...newQuery });

      // delete empty fields
      Object.keys(query).forEach(key => {
        if (query[key] === undefined || query[key] === null || query[key] === '') {
          delete query[key];
        }
      })

      if (toHistory) {
        await this.$router.push({ query })
      } else {
        await this.$router.replace({ query }).catch(err => { }) // or t: new Date().getTime(), - timestamp
      }
    },
    paramFromQuery() {
      this.page = Number(this.$route.query?.page) || 1
      this.per_page = Number(this.$route.query?.per_page) || 25

      // init company
      // this.company_id = Number(this.$route.query?.company_id) || "" // simple select case   
      const company_id = Number(this.$route.query?.company_id) || ""

      if (company_id || company_id === 0) {
        // console.log('has company_id: ', company_id)
        this.company = this.companies.find(c => c.id === company_id)
      } else {
        this.company = null
      }

      this.refreshKey += 1

      // TODO: sort
    },
    async loadData() {
      try {
        this.loading = true;
        this.error = "";

        if (this.role === 'admin') {
          const response = await this.getCompanyList({ per_page: 100, page: 1 });
          this.companies = response?.data?.list?.list ?? []
        }

        await this.loadTableData(false);
      } catch (error) {
        console.log("loadData Error: ", error);
        this.error = error
      } finally {
        this.loading = false;
      }
    },
    async loadTableData(silent = true) {
      if (this.wait) {
        return
      }

      this.wait = true

      // console.log('loadTableData...')
      this.paramFromQuery();

      try {
        if (!silent) {
          this.loading = true;
        }
        this.error = "";

        const company_id = Number(this.company?.id) || ""

        const response = await this.getTrackerList({ per_page: this.per_page, page: this.page, company_id });
        this.tableData = response.data.list.list || [];
        this.totalRows = response.data.list.total;

      } catch (error) {
        console.log("loadTableData Error: ", error);
        this.error = error;
      } finally {
        this.loading = false;
        this.wait = false
      }
    },

    handleEditTracker(tracker) {
      this.tracker = { ...tracker };
      this.showModal = true;
    },
    addTracker() {
      if (this.role === 'admin') {
        this.tracker = {
          company_id: this.company?.id || "",
          company_name: this.company?.name || ""
        };
      }

      if (this.role === 'company') {
        // owner === company
        this.tracker = {
          company_id: this.owner.id,
          company_name: this.owner.name
        };
      }

      this.showModal = true;
    },
    submitForm() {
      this.loadTableData(false);
      this.showModal = false;
    },
  },
  watch: {
    // watch query like nuxt watchQuery
    '$route.query'(q, old) {
      if (
        ((q.page || old.page) && (q.page !== old.page)) ||
        ((q.per_page || old.per_page) && (q.per_page !== old.per_page)) ||
        (this.role === 'admin' && (q.company_id || old.company_id) && (q.company_id !== old.company_id))
        // ((q.sort || old.sort) && (q.sort !== old.sort)) ||
        // ((q.sortd || old.sortd) && (q.sortd !== old.sortd)) ||
        // ((q.categ_id?.length !== old.categ_id?.length)) || // # compare arrays
      ) {
        this.loadTableData()
      }
    },
    page(page) {
      this.setQuery({ page }, true)
    },
    per_page(per_page) {
      this.setQuery({ page: 1, per_page }, true)
    },
    'company.id'(company_id) {
      this.setQuery({ page: 1, company_id }, true)
    },
    block(block) {
      this.setQuery({ page: 1, block }, true)
    },
  },
};
</script>
<style scoped lang="scss">
.table-trackers :deep(.cell-action) {
  width: 66px;
}
</style>
